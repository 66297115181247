/**
 * The angular app for the homepage.
 * All modules should be registered here.
 */
const angular = require('angular');
angular.module('NeueApp', [
  require('angular-sanitize'),
  require('angular-cookies')
]).
  // Configuration
  config(require('../spectrum/config.js')).

  // Controllers
  controller('FamilyAutoCompleteSearchController',
    require('../spectrum/controllers/family_autocomplete_search_controller.js')).
  controller('MarketplaceHeaderController',
    require('../spectrum/controllers/marketplace_header_controller.js')).
  controller('MarketplaceHomeController',
    require('../spectrum/controllers/marketplace_home_controller.js')).
  controller('NotificationController',
    require('../spectrum/controllers/notification_controller.js')).
  controller('VisualSearchesController',
    require('../spectrum/controllers/visual_searches_controller.js')).

  // Spectrum Component Directives
  directive('feedbackIcons',
    require('../spectrum/directives/feedback_icons/directive.js')).

  // Services
  factory('AddFontsToWebProjectService',
    require('../spectrum/services/add_fonts_to_web_project_service.js')).
  factory('AuthenticationService',
    require('../spectrum/services/authentication_service.js')).
  factory('DataService',
    require('../spectrum/services/data_service.js')).
  factory('DialogService',
    require('../spectrum/services/dialog_service.js')).
  factory('FamilyAutocompleteSearchService',
    require('../spectrum/services/family_autocomplete_search_service.js')).
  factory('FeedbackService',
    require('../spectrum/services/feedback_service.js')).
  factory('FirstMileService',
    require('../spectrum/services/first_mile_service.js')).
  factory('FloodgateService',
    require('../spectrum/services/floodgate_service.js')).
  factory('FontCountService',
    require('../spectrum/services/font_count_service.js')).
  factory('FontLoaderService',
    require('../spectrum/services/font_loader_service.js')).
  factory('FreezeframeService',
    require('../spectrum/services/freezeframe_service.js')).
  factory('I18nService',
    require('../spectrum/services/i18n_service.js')).
  factory('ImsEventsService',
    require('../spectrum/services/ims_events_service.js')).
  factory('NewrelicService',
    require('../spectrum/services/newrelic_service.js')).
  factory('NewrelicClickTrackerService',
    require('../spectrum/services/newrelic_click_tracker_service.js')).
  factory('NotificationService',
    require('../spectrum/services/notification_service.js')).
  factory('PostLoginActionCreatorService',
    require('../spectrum/services/post_login_action_creator_service.js')).
  factory('RedirectContextService',
    require('../spectrum/services/redirect_context_service.js')).
  factory('SuspendedAccountService',
    require('../spectrum/services/suspended_account_service.js')).
  factory('SyncService',
    require('../spectrum/services/sync_service.js')).
  factory('UserPrefsService',
    require('../spectrum/services/user_prefs_service.js')).

  // Use DataService to preload data from script tags on the page.
  run(require('../spectrum/run.js'));
