const _ = require('underscore');
const freezeframeUtil = require('./freezeframe_util.js');

const cache = {};
const sampleTextCache = {};

export async function fetchRecommendedFonts({ familiesPerPage, isPersonalized, page, type }) {
  cache[type] = cache[type] || {};

  if (cache[type][page]) {
    return Promise.resolve(
      cache[type][page]);
  }

  const params = new URLSearchParams();
  params.append('page', page || 1);
  params.append('families_per_page', familiesPerPage || 16);
  params.append('personalized', !!isPersonalized);

  const response =
    await fetch(`/recommendations/${type}.json?${params.toString()}`);

  const data = await response.json();
  const items = getItems(data);

  cache[type][page] = items;
  return items;
}

/**
 * Returns example text for the font's default language.
 * To show more variety, sample text for the dropdown menu is chosen at random for languages where it exists.
 *
 * @param {Object} family
 * @param {Object} displayFont
 * @param {Object} textSampleData
 * @return {String}
 */
function getSampleText(family, displayFont, textSampleData) {
  if (!family || !displayFont || !textSampleData) {
    return;
  }

  if (sampleTextCache[family.slug]) {
    return sampleTextCache[family.slug];
  }
  const featureSettings = textSampleData.textSamples[displayFont.default_language].feature_settings;
  const unicodeRange = textSampleData.textSamples[displayFont.default_language].unicode_range;

  sampleTextCache[family.slug] = {featureSettings: featureSettings, unicodeRange: unicodeRange};

  if (family.display_font.default_language === 'en') {
    sampleTextCache[family.slug]['textSample'] = _.sample(_.pick(textSampleData.textSampleOptions, 'fez', 'roger', 'fox'));
  } else if (family.display_font.default_language === 'ja') {
    sampleTextCache[family.slug]['textSample'] = _.sample(_.pick(textSampleData.textSampleOptions,
        'typeface', 'letters', 'train', 'bamboo', 'typography'));
  } else {
    sampleTextCache[family.slug]['textSample'] = textSampleData.textSamples[family.display_font.default_language].list;
  }
  return sampleTextCache[family.slug];
}

function getItems(data = {}) {
  if (!data.families || !data.textSampleData) {
    return;
  }

  return data.families.map(item => {
    const displayFont = item.font || item.display_font;

    const sampleTextInfo = getSampleText(item, displayFont, data.textSampleData);
    const imageUrl = freezeframeUtil.getStillImageUrl(item.specimen_images_data);

    return {
      fvd: displayFont.font.web.fvd,
      title: item.name,
      subtitle: item.foundry.name,
      src: imageUrl,
      href: `/fonts/${item.slug}`,
      webId: displayFont.family.web_id,
      unicodeRange: sampleTextInfo.unicodeRange,
      featureSettings: sampleTextInfo.featureSettings,
      textSample: sampleTextInfo.textSample
    };
  });
}

export const exportedForTesting = {
  getSampleText,
  getItems
};
