import { setLocale } from '@adobe-fonts/site/lib/I18N.js';
import { localizeSharedMessages } from './shared.i18n.js';

export async function switchLocale(locale) {
  const carouselMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/carousel/i18n/${locale}.json`);

  const ccxMessages = await import(
    /* webpackChunkName: "i18n-[request]" */
    `@adobe-fonts/site/components/ccx/i18n/${locale}.json`);

  const ccxSpecimenCardMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/ccx/ccx-specimen-card/i18n/${locale}.json`);

  const specimenCardMessages = await import(
      /* webpackChunkName: "i18n-[request]" */
      `@adobe-fonts/site/components/specimen-card/i18n/${locale}.json`);

  const sharedMessages = await localizeSharedMessages(locale);

  setLocale(locale, {
    ...sharedMessages,
    ...carouselMessages.default,
    ...ccxMessages.default,
    ...ccxSpecimenCardMessages.default,
    ...specimenCardMessages.default
  });
}
