import { setupGlobals } from '../util/GlobalSetup.js';
setupGlobals();

import '../chunks/polyfills.js';

import { init as initHome } from '../controllers/home.js';
import { switchLocale } from '../i18n/home.i18n.js';
import '../chunks/shared-webcomponents.js';
import '../chunks/home-webcomponents.js';
import 'angular';
import '../angular_apps/home.js';
import '../../../../vendor/assets/javascripts/google-analytics-shim.js';

import { initI18n } from '../util/i18n_util.js';
import { initFlipper } from '../util/flipper.js';

initI18n(switchLocale);
initFlipper();
initHome();
